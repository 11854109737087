<template>
  <div class="upload">
    <div class="amount">
      <p>调解金额(元)</p>
      <p class="number">￥{{ adjustAmount }}</p>
    </div>
    <ul class="main">
      <li class="space-between">
        <span>还款金额</span>
        <span
          ><input type="number" placeholder="请输入" v-model="amount"
        /></span>
      </li>
      <li>
        <van-uploader :after-read="afterRead" v-model="fileList" multiple />
      </li>
    </ul>
    <div class="footer-button">
      <button class="button" @click="uploadFile" :disabled="disabledBtn">
        我已还款
      </button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { uploadFile } from "../../services/api";
export default {
  name: "UploadFile",
  components: {},
  data() {
    return {
      fileList: [],
      amount: "",
      imglist: [],
      loanId: "",
      adjustAmount: "",
      disabledBtn: false,
    };
  },

  beforeCreate() {
    console.log(this.$route.query);
    if (this.$route.query.title) {
      document.title = "执行调解查询";
    }
  },

  mounted() {
    this.loanId = this.$route.query.loanId;
    this.adjustAmount = this.$route.query.adjustAmount;
    this.amount = this.$route.query.adjustAmount;
  },

  methods: {
    afterRead(file) {
      console.log(file);
    },
    uploadFile() {
      this.disabledBtn = true;
      if (!this.amount) {
        Toast("请填写还款金额");
        return;
      }
      if (!this.fileList.length) {
        Toast("请上传还款凭证");
        return;
      }
      let formFileList = [];
      this.fileList.map((file) => {
        formFileList.push(file.file);
      });
      let formData = new FormData();
      formData.append("amount", this.amount);
      formData.append("loanId", this.loanId);
      formFileList.forEach((file) => {
        formData.append("files", file);
      });
      uploadFile(formData).then((res) => {
        if (res.success) {
          // Toast(res.message);
          Toast.loading({
            message: res.message,
            forbidClick: true,
          });
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else {
          this.disabledBtn = false;
          Toast(res.message);
        }
      });
    },
  },
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
